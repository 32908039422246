import "../../styles/section.css";
import "./carta.css";

import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Trans } from "react-i18next";
import { graphql } from "gatsby";
// import { Parallax } from "react-scroll-parallax";
import { Link, useI18next } from "gatsby-plugin-react-i18next";

import Layout from "../../components/layout/Layout";
import Seo from "../../components/seo";
import SectionLanding from "../../components/section/sectionLanding";
import Carta from "../../components/carta/Carta";

// import CartaSplitter from "../../components/carta/CartaSplitter.svg"
// import IconDesplegable from "../../svg/icons/iconDesplegable.svg";
import Subrayado2 from "../../svg/subrayados/subrayado02.svg";
// import Subrayado3 from "../../svg/subrayados/subrayado03.svg";
// import Circulo1 from "../../svg/subrayados/circulo1.svg";
import Mask1 from "../../svg/masks/mask1.svg";
import Mask3 from "../../svg/masks/mask3.svg";
import Mask4 from "../../svg/masks/mask4.svg";
import MaskSq1 from "../../svg/masks/maskSq1.svg";
import MaskSq2 from "../../svg/masks/maskSq2.svg";
import MaskSq3 from "../../svg/masks/maskSq3.svg";
import MaskSq4 from "../../svg/masks/maskSq4.svg";

export default function IndexPage() {
	const { t } = useI18next();

	return (
		<Layout footerText={t("El placer de las cosas pequeñas")}>
			<Seo title={t("Carta")} />
			<StaticImage
				src={`../../images/carta.jpg`}
				className="landing-bg"
				alt="Section background"
				layout="fullWidth"
			/>

			<MaskSq1 />
			<MaskSq2 />
			<MaskSq3 />
			<MaskSq4 />
			<Mask1 />
			<Mask3 />
			<Mask4 />

			<div className="landingSection">
				<SectionLanding
					title={
						<Trans i18nKey="cartaLandingTitle">
							Descubre <i>nuestra&nbsp;carta</i>
						</Trans>
					}
					subtitle={t("Somos reales, no perfectos")}
					orbitalText={t("Bienestar")}
					section="carta"
					hideScroll={true}
				>
					<div className="cartaLandingButtons">
						<a href="#sectionContent2" aria-label={t("Ver carta")}>
							<h2 className="a lowercase">
								<Trans>Ver carta</Trans>
								<Subrayado2 />
							</h2>
						</a>

						<Link to="/menus#sectionContent2">
							<h2 className="a lowercase">
								<Trans>Ver menús</Trans>
								<Subrayado2 />
							</h2>
						</Link>
					</div>
				</SectionLanding>
			</div>

			<Carta />
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
